import { addDoc, collection } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { Article } from '@/components/article'
import { Layout } from '@/components/layout'
import { db } from './firebaseConfig'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/* eslint-disable */

const ContactForm: React.FC = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => {
    const auth = getAuth()
    signInAnonymously(auth)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUserId(user.uid)
          }
        })
      })
      .catch((error) => {
        console.error('Error signing in anonymously:', error)
      })
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    setLoading(true)
    const token = await executeRecaptcha('submit')

    try {
      const docRef = await addDoc(collection(db, 'supportRequests'), {
        name,
        email,
        question,
        timestamp: new Date(),
        recaptchaToken: token,
        userId,
      })

      console.log('Document written with ID: ', docRef.id)
      setMessage({ type: 'success', text: 'Your question has been submitted!' })

      // Clear the form
      setName('')
      setEmail('')
      setQuestion('')
    } catch (e) {
      console.error('Error adding document: ', e)
      setMessage({
        type: 'error',
        text: 'There was an error submitting your question. Please try again.',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mx-auto mt-10 max-w-md rounded-lg bg-white p-5 shadow-md">
      <h2 className="mb-5 text-2xl font-bold">Contact Us</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Question:</label>
          <textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
            className="focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            className="bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 w-full rounded-md px-4 py-2 font-semibold text-white shadow-md focus:outline-none focus:ring-2"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
      {message && (
        <div
          className={`mt-5 p-4 ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'} rounded-md`}
        >
          {message.text}
        </div>
      )}
    </div>
  )
}

export default function SupportPage() {
  return (
    <Layout>
      <Article
        title="FAQ's"
        imageAlt="sundry solutions"
        imageSrc={require('../assets/support-img-001.png')}
      >
        <ContactForm />
      </Article>
    </Layout>
  )
}
