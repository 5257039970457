import { Link } from 'react-router-dom'
import { Logo } from '@/components/logo'

/* eslint-disable */

function Header({ title }: { title?: string }) {
  return (
    <header className="relative py-6">
      <div
        className="max-w-6xl lg:w-3/5 ml-0 bg-sundry-green"
        style={{
          backgroundColor: '#30809d',
        }}
      >
        <div className="relative flex">
          <h1 className="m-0 text-xl font-bold uppercase leading-none">
            <Link to="/" className="flex items-center gap-2 no-underline">
              <Logo />
              <span>{title}</span>
            </Link>
          </h1>
        </div>
      </div>
      <div className="flex justify-center max-w-6xl lg:w-3/5">
        <iframe
          className="border-none mt-2"
          src="https://winfacil.com/ads/0DXifJfLInZ9ABNm2TsQ?l=loc-us-001"
          style={{marginLeft: 50, height: 200}}
        />
      </div>
    </header>
  )
}

export default Header
