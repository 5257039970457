import { Article } from '@/components/article'
import { Layout } from '@/components/layout'

function AboutPage() {
  return (
    <Layout>
      <Article
        title="About"
        imageAlt="Lorem Picsum"
        imageSrc={require('../assets/about-img-001.png')}
      >
        <p>
          At Sundry Solutions, we are dedicated to helping your business thrive in the digital age.
          Our comprehensive advertising services allow you to promote your business online, on
          mobile devices, and on tablets. By simply clicking the provided link and filling out a
          quick questionnaire, you can get started on a customized advertising plan tailored to your
          specific needs.
        </p>
        <p>
          Managing leads can be a daunting task, but with our advanced lead management solutions,
          communication automation for your Lead Ad campaigns becomes seamless. Our leads workflow
          system ensures that you can easily track and engage with potential customers, helping you
          convert leads into loyal clients.
        </p>
        <p>
          Your business's online presence is crucial, and we understand the importance of making a
          great first impression. Whether you need a professional website or a compelling social
          media page, our team is here to build a business face that truly represents who you are.
          We focus on creating beautiful, functional, and user-friendly designs that leave a lasting
          impact.
        </p>
        <p>
          We take pride in offering custom tools and applications that provide a unique, personable
          interaction. Our goal is to ensure you get the most out of our services, with solutions
          specifically designed to meet your business's unique needs. We believe in forging strong
          relationships with our clients, offering support and expertise every step of the way.
        </p>
        <p>
          Affordability is at the core of our mission. We are committed to delivering effective
          business campaigns that are not only high-quality but also budget-friendly. Our tailored
          campaigns are designed to ensure the success of your business without breaking the bank.
          At Sundry Solutions, we strive to provide the best value, helping your business grow and
          succeed.
        </p>
      </Article>
    </Layout>
  )
}

export default AboutPage
