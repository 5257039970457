import { Menu } from '@/components/menu'
import { SocialLinks } from '@/components/social-links'

function Footer() {
  return (
    <footer className="bg-primary-400 py-6 text-sm leading-5 tracking-normal text-white lg:bg-transparent lg:text-gray-400">
      <div className="mx-auto w-full max-w-6xl px-6">
        <div className="relative flex flex-col items-center gap-6 lg:flex-row lg:items-end lg:justify-between">
          <nav className="flex flex-col items-center gap-6 lg:order-1 lg:items-end">
            <SocialLinks className="flex gap-4" />
            <Menu className="flex gap-4" />
          </nav>
          <footer
            style={{
              marginLeft: '7%',
              width: '50%',
            }}
          >
            <div className="flex w-full flex-col pt-3 text-right">
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sundrymarketingsolutions.com"
                >
                  &copy; Sundry Group Enterprises Corp
                </a>
              </p>
            </div>
            <p>
              Payment options{' '}
              <img
                src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppppcmcvdam.png"
                alt="Pay with PayPal, PayPal Credit or any major credit card"
              />
            </p>
            {/* <div className="">&copy; Sundry Group Enterprises Corp</div> */}
          </footer>
        </div>
      </div>
    </footer>
  )
}

export default Footer
