import { Article } from '@/components/article'
import { Layout } from '@/components/layout'

export default function ContactPage() {
  return (
    <Layout>
      <Article
        title="Contact"
        imageAlt="Lorem Picsum"
        imageSrc={require('../assets/contact-img-001.png')}
      >
        <p>
          We appreciate your interest in Sundry Solutions. If you have any questions, need
          assistance, or would like to learn more about our services, please reach out to us. Our
          dedicated support team is here to help you.
        </p>
        <p>
          Email:{' '}
          <a href="mailto:support@sundrymarketingsolutions.com">
            support@sundrymarketingsolutions.com
          </a>
        </p>
        <p>
          Please include your name, contact information, and a brief description of your inquiry,
          and we will get back to you as soon as possible.
        </p>
        <p>Thank you for choosing Sundry Solutions. We look forward to assisting you.</p>
      </Article>
    </Layout>
  )
}
