import logo from '../../assets/logo.png'

function Logo({ className }: { className?: string }) {
  return (
    <div>
      <img src={logo} alt="logo" width={120} />
    </div>
  )
}

export default Logo
