import { Article } from '@/components/article'
import { Layout } from '@/components/layout'

/* eslint-disable */

export default function FaqsPage() {
  return (
    <Layout>
      <Article
        title="FAQ's"
        imageAlt="faqs"
        imageSrc={require('../assets/faq-img-001.png')}
      >
        <p>Frequently Asked Questions.</p>
        <details
          className="mt-4 block rounded-sm border px-4 open:border-primary-400 hover:border-primary-300"
          open
        >
          <summary className="-mx-4 cursor-pointer border-primary-200 px-4 py-3">
            How can I promote my business online, on mobile, and on tablets?
          </summary>
          <p>
            You can promote your business online, on mobile, and on tablets by clicking the provided
            link and filling out the questionnaire to get started with our customized advertising
            plan.
          </p>
        </details>
        <details className="mt-4 block rounded-sm border border-gray-200 px-4 hover:border-primary-300">
          <summary className="-mx-4 cursor-pointer px-4 py-3">
            How can I manage my leads effectively?
          </summary>
          <p>
            Our lead management solutions provide easy communication automation for your Lead Ad
            campaigns. Our leads workflow system ensures you can easily track and engage with
            potential customers.
          </p>
        </details>
        <details className="mt-4 block rounded-sm border border-gray-200 px-4 hover:border-primary-300">
          <summary className="-mx-4 cursor-pointer px-4 py-3">
            How can I build a professional online presence?
          </summary>
          <p>
            We can help you build a beautiful, professional website and/or social media page that
            represents who you are. Our team focuses on creating designs that are both functional
            and visually appealing.
          </p>
        </details>

        <details className="mt-4 block rounded-sm border border-gray-200 px-4 hover:border-primary-300">
          <summary className="-mx-4 cursor-pointer px-4 py-3">
            What kind of custom tools and applications do you offer?
          </summary>
          <p>
            We offer custom tools and applications designed to provide a unique, personable
            interaction. Our goal is to ensure you get the most from our services, with solutions
            tailored to your business's specific needs.
          </p>
        </details>

        <details className="mt-4 block rounded-sm border border-gray-200 px-4 hover:border-primary-300">
          <summary className="-mx-4 cursor-pointer px-4 py-3">
            Are your business campaigns affordable?
          </summary>
          <p>
            Yes, we are committed to delivering high-quality, budget-friendly business campaigns.
            Our tailored campaigns are designed to ensure the success of your business without
            breaking the bank.
          </p>
        </details>
      </Article>
    </Layout>
  )
}
