import { getFirestore } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyC3KB1YT5ijiO58y8AdwSWvCSssKhMK9_g',
  authDomain: 'sundryleadssolutions.firebaseapp.com',
  databaseURL: 'https://sundryleadssolutions.firebaseio.com',
  projectId: 'sundryleadssolutions',
  storageBucket: 'sundryleadssolutions.appspot.com',
  messagingSenderId: '101101099681',
  appId: 'sundryleadssolutions',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }
