import './style.css'

import { Layout } from '@/components/layout'

/* eslint-disable */
export default function HomePage() {
  return (
    <Layout>
      <div className="ml-5 flex w-full flex-col lg:w-3/5">
        <div className="flex flex-col items-center justify-center">
          <div className="bg-slate-800 flex flex-col items-center justify-center gap-1 p-3 text-white">
            <h1 className="text-2xl">Sundry Solutions</h1>
            <p className="lead">Linking the Community with You</p>
          </div>
        </div>

        <div className="pt-md-2 row">
          <div className="pt-md-0 col-12 col-md-4 w-full pt-3 sm:w-1/3">
            <h3 className="text-2xl">Advertise </h3>
            <p>
              Promote your business online, mobile and/or tablet today! Please click of the link
              provided and fill out the questionnaire to get started.
            </p>
            <p>
              <a href="/form"> Advertise Form</a>
            </p>
          </div>
          <div className="pt-md-0 col-12 col-md-4 w-full  pt-3 sm:w-1/3">
            <h3 className="text-2xl">Lead Management </h3>
            <p>Easy communication automation for your Lead Ad campaigns.</p>
            <p>
              <a href="/leads"> Leads Workflow</a>
            </p>
          </div>
          <div className="pt-md-0 col-12 col-md-4 w-full  pt-3 sm:w-1/3">
            <h3 className="text-2xl">Business Face </h3>
            <p>
              If you need help building your business face, we can help. We will make sure you have
              a beautiful professional website and/or social media page that represents who you are.
            </p>
            <p></p>
          </div>

          <div className="pt-md-0 col-12 col-md-4 w-full pt-3 sm:w-1/3">
            <h3 className="text-2xl">Custom tools and applications </h3>
            <p>
              We believe in providing our clients with a unique personable interaction to ensure
              that you receive the most from our tools and services.{' '}
            </p>
            <p></p>
          </div>
          <div className="pt-md-0 col-12 col-md-4 w-full pt-3 sm:w-1/3">
            <h3 className="text-2xl">Affordable </h3>
            <p>
              You bet! We believe in creating and providing affordable business campaigns tailored
              made to ensure the success of your business.{' '}
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
